@import 'scss/vendors/include-media';

.guest-app {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #c50509;
    background-image: linear-gradient(160deg,#c50509,#000003);
}