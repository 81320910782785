@import 'scss/vendors/include-media';

.multi-select-subcontractor {
  margin-bottom: 15px;

  .ant-row {
    width: 100%;
  }

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }

  .ant-select {
    width: 100%;
  }


  .btn-text {
    cursor: pointer;
    color: #fff;
    margin-left: 15px;
  }
}