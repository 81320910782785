.group-checkbox {
  position: relative;
  display: block !important;
  font-weight: normal;
  overflow-y: auto;
  padding: 15px 0 9px !important;
  margin-bottom: 10px !important;

  .ant-checkbox-group-item {
    display: flex;
    margin-bottom: 10px;
  }
}


.height-checkbox-group-auto {

  &.search-checkbox-group-none {
    .filter-search {
      display: none;
    }
  }

  .group-checkbox {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
  }

  .holder-group-select {
    height: auto !important;
  }

  .inner {
    height: auto !important;
  }
}