@import 'scss/base/variables';
@import 'scss/base/mixins';
@import 'scss/vendors/include-media';



.holder-profile-link {
  display: flex;
  cursor: pointer;
  margin-left: auto;

  .answer-count {
    position: relative;
    font-size: 18px;
    margin-right: 12px;
    padding-top: 4px;


    @include media('>=tablet') {
      font-size: 24px;
      margin-right: 15px;
    }

    .badge {
      position: absolute;
      right: -3px;
      top: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 10px;
      height: 10px;
      border-radius: 50px;
      font-size: 8px;
      background: $primary-color;
      color: #fff;

      @include media('>=tablet') {
        right: -7px;
        top: -2px;
        font-size: 12px;
        min-width: 20px;
        height: 20px;
      }
    }
  }
}



.my-profile-link {
  display: flex;
  align-items: center;


  &:hover {
    @include animate(color);
    color: $primary-color;
  }

  .profile-info {
    @include media('<tablet') {
      display: none;
    }
  }


  .anticon-down {
    margin-left: 6px;
  }

  .profile-icon {
    font-size: 18px;
    margin-right: 2px;

    @include media('>=tablet') {
      font-size: 24px;
      margin-right: 7px;
    }
  }
}

.dropdown-profile {
  .ant-dropdown-menu-item {
    background-color: transparent !important;

    .ant-dropdown-menu-title-content {
      color: $charleston-green;

      &:hover {
        color: $primary-color;
      }
    }
  }
}